import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { format } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'intToDate',
  pure: false // Esto permite que el pipe se vuelva a calcular si cambia el idioma
})
export class IntToDatePipe implements PipeTransform {

  private lastValue: any;
  private lastLang: string | null = null;
  private langChangeSubscription: Subscription;

  constructor(
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    // Suscribirse a los cambios de idioma
    this.langChangeSubscription = this.translateService.onLangChange.subscribe(() => {
      this.lastLang = null;  // Resetea para forzar el recalculo
      this.cdr.markForCheck();  // Forzar la actualización de la vista
    });
  }

  transform(value: any, dateFormat?: string): any {
    if (value === 0) {
      return 0;
    }

    if(!value){
      return 0
    }

    // Evitar recalcular si no hay cambio de idioma ni de valor
    const currentLang = this.translateService.currentLang;
    if (this.lastValue === value && this.lastLang === currentLang) {
      return this.lastValue;
    }

    // Definir el locale según el idioma actual
    const locale = currentLang === 'es' ? es : enUS;

    // Definir formato de fecha por defecto según el idioma si no se proporciona
    if (!dateFormat) {
      dateFormat = currentLang === 'es' ? 'dd.MM.yyyy' : 'MM.dd.yyyy';
    }

    // Formatear la fecha
    this.lastValue = format(new Date(value * 1000), dateFormat, { locale });
    this.lastLang = currentLang;

    return this.lastValue;
  }

  // Desuscribir cuando el pipe no se necesita más para evitar fugas de memoria
  ngOnDestroy() {
    this.langChangeSubscription.unsubscribe();
  }
}

